import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card mb-5 mb-xl-10" }
const _hoisted_2 = {
  id: "kt_account_profile_details",
  class: "collapse show"
}
const _hoisted_3 = { class: "card-body top p-0" }
const _hoisted_4 = { style: {"height":"64vh","width":"100%"} }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-body text-start" }
const _hoisted_7 = { class: "fs-5 fw-bolder" }
const _hoisted_8 = { class: "fw-bold" }
const _hoisted_9 = { class: "text-muted" }
const _hoisted_10 = { class: "" }
const _hoisted_11 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_l_tile_layer = _resolveComponent("l-tile-layer")!
  const _component_l_control_layers = _resolveComponent("l-control-layers")!
  const _component_l_control_zoom = _resolveComponent("l-control-zoom")!
  const _component_l_icon = _resolveComponent("l-icon")!
  const _component_l_popup = _resolveComponent("l-popup")!
  const _component_l_marker = _resolveComponent("l-marker")!
  const _component_l_map = _resolveComponent("l-map")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_l_map, {
            modelValue: _ctx.zoom,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.zoom) = $event)),
            zoom: _ctx.zoom,
            "onUpdate:zoom": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.zoom) = $event)),
            zoomAnimation: true,
            options: { zoomControl: false },
            center: [-6.304159, 106.724997]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_l_tile_layer, {
                url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                "layer-type": "base",
                name: "OpenStreetMap"
              }),
              _createVNode(_component_l_control_layers),
              _createVNode(_component_l_control_zoom, { position: "bottomright" }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.markers, (item, index) => {
                return (_openBlock(), _createBlock(_component_l_marker, {
                  key: index,
                  "lat-lng": [item.issu.latitude, item.issu.longitude]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_l_icon, {
                      "icon-url": _ctx.icon,
                      "icon-size": _ctx.iconSize
                    }, null, 8, ["icon-url", "icon-size"]),
                    _createVNode(_component_l_popup, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("div", _hoisted_7, _toDisplayString(item.territory.name), 1),
                            _createElementVNode("div", _hoisted_8, " oleh : " + _toDisplayString(item.created_by.name), 1),
                            _createElementVNode("div", _hoisted_9, _toDisplayString(item.issu.date), 1),
                            _createElementVNode("div", _hoisted_10, _toDisplayString(item.issu.description), 1),
                            _createElementVNode("img", {
                              class: "img-fluid",
                              style: {"max-height":"100px"},
                              src: item.issu.foto,
                              alt: "foto kegiatan"
                            }, null, 8, _hoisted_11)
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["lat-lng"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue", "zoom", "center"])
        ])
      ])
    ])
  ]))
}